import $ from "jquery";

import("swiper/scss");
import("swiper/scss/effect-fade");

(async () => {
  const { default: Swiper } = await import("swiper");
  const { Autoplay, Pagination, EffectFade } = await import("swiper/modules");

  $(".hero-banner--type-2").each(function () {
    const leftSlider = new Swiper($(this).find(".block--left .swiper")[0], {
      modules: [Autoplay, Pagination, EffectFade],
      autoplay: true,
      speed: 1000,
      spaceBetween: 0,
      effect: "fade",
      slidesPerView: 1,
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: $(this).find(".progress-bar")[0],
        type: "progressbar",
      },
      on: {
        slideChange: function () {
          $(this.el)
            .find(".fraction-current")
            .text((this.realIndex + 1).toString().padStart(2, "0"));
        },
      },
    });

    const rightSlider = new Swiper($(this).find(".block--right .swiper")[0], {
      modules: [EffectFade],
      slidesPerView: 1,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
    });

    leftSlider.on("slideChange", () => {
      rightSlider.slideTo(leftSlider.activeIndex);
    });

    rightSlider.on("slideChange", () => {
      leftSlider.slideTo(rightSlider.activeIndex);
    });
  });
})();
